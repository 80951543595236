.default-animation {
	@include transition(all 0.5s ease);
}

.padding-horiz {
	padding-left: $padding-horiz;
	padding-right: $padding-horiz;
	@include breakpoint(desktop-only) {
		padding-left: $padding-horiz - 3rem;
		padding-right: $padding-horiz - 3rem;
	}

	@include breakpoint(mobile-only) {
		padding-left: $padding-horiz - 6rem;
		padding-right: $padding-horiz - 6rem;
	}
}

.padding-vert {
	padding-top: $padding-vert;
	padding-bottom: $padding-vert;
}
