// Sizes
$header-height: 5rem;
$footer-height: 6rem;
$border-radius: 3px;

// Font Weights
$font-weight-bold: 600;
$font-weight-regular: 400;
$font-weight-light:300;

// Global Paddings
$padding-horiz: 7rem;
$padding-vert: 3rem;
