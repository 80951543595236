table {
	border: 1px solid lighten($core-border-color,5%);
	table-layout: auto;
}

th {
	@extend strong;
	background: lighten($core-border-color,10%);
	padding: 0.5rem;
}

td {
	padding: 0.5rem;
	border: 1px solid lighten($core-border-color,5%); 
}
