// Core
$core-text:						#444;
$core-accent:					#1BB3E9;
$secondary-link:				#F6635E;
$white:							#fff;
$black:							#000;
$light-gray:					#ddd;
$border-color: 					#EEEEEE;

// Borders
$core-border-color:				$light-gray;
$core-border-color-hover: 		darken($core-border-color, 10);
$core-border-color-focus: 		$core-accent;

// Shadows
$core-box-shadow: 				inset 0 1px 3px hsla(0, 0%, 0%, 0.06);
$core-box-shadow-focus: 		$core-box-shadow, 0 0 5px rgba(darken($core-border-color-focus, 5), 0.7);

// Background
$page-bg:						#fff;

// Header
$header-text: 					#FFFFFF;

// Nav
$navbar-text: 					#75879A;
$navbar-bg: 					#FFFFFF;

// Showcase
$showcase-bg:					lighten($core-accent, 6%);
$showcase-text:					#fff;

// Feature
$feature-bg:					#fff;

// Main Body
$main-bg:						#f7f7f7;
$body-border:					darken($main-bg, 5%);

// Sidebar
$sidebar-text:					#aaa;

// Bottom
$bottom-bg:						#f7f7f7;
$bottom-text:					$core-text;

// Footer
$footer-bg:						#333;
$footer-text:					#999;

$rule-color: 					#F0F2F4;
$code-text:						#c7254e;
$code-bg:						#f9f2f4;
$pre-text:						#237794;
$pre-bg:						#f0f0f0;

// Dark Contrast variation
$dark-navbar-text:				#999;
$dark-sidebar:					#222;
$dark-sidebar-text:				#999;
$dark-main-bg:					#333;
$dark-body-border:				#666;

// Info - Yellow
$notes-info-border:     #F0AD4E;
$notes-info-bg:         #FCF8F2;
// Warning - Red
$notes-warning-border:  #D9534F;
$notes-warning-bg:      #FDF7F7;
// Note - Blue
$notes-note-border:     #5BC0DE;
$notes-note-bg:         #F4F8FA;
// Success - Green
$notes-success-border:  #5CB85C;
$notes-success-bg:      #F1F9F1;
