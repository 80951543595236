// Header styling
$dropdown-color: #f6f6f6;

#header {
	@extend .default-animation;
	@extend .padding-horiz;
	position: fixed;
	z-index: 10;
	width: 100%;
	height: $header-height;
	background-color: rgba(255,255,255,0.90);
	box-shadow: 0 0.05rem 1rem rgba(0,0,0, 0.15);

	// scroll based changes
	&.scrolled {
		height: $header-height - 2rem;
		background-color: rgba(255,255,255,0.90) !important;
		box-shadow: 0 0.05rem 1rem rgba(0,0,0, 0.15) !important;

		#logo h3 {
			color: $core-text !important;
			font-size: 1.6rem !important;
		}
		#logo a, #navbar span {
			color: $core-text !important;
		}
		#navbar a {
			color: $core-accent !important;
            &:hover {
                color: darken($core-accent, 20%) !important;
            }
		}
		#navbar a:before, #navbar a:after {
			background-color: $core-accent !important;
		}

		.navigation {
			margin-top: 0.5rem !important;
		}
	}

	// set heights for vertical centering
	> .grid, #logo, #navbar {
		height: 100%;
	}

	#logo {
		float: left;
		h3 {
			@extend .default-animation;
			@extend %vertical-align;
			font-size: 2rem;
			line-height: 2rem;
			margin: 0;
			text-transform: uppercase;
			a {
				color: $core-text;
			}
		}
	}

	#navbar {
		font-size: $core-font-size - 0.1rem;

		/* Child Indicator */
		.has-children {

			& > a {
				& > span {
					display: inline-block;
					padding-right: 8px;

					&:after {
						font-family: FontAwesome;
						content: '\f107';
						position: absolute;
						display: inline-block;
						right: 8px;
						top: 4px;
					}
				}
				&:after, &:before {
					display: none;
				}
			}

			& .has-children > a > span:after {
				content: '\f105';
			}
		}

		.navigation > .has-children:hover > a {
			background: $dropdown-color;
			border: 1px solid darken($dropdown-color, 4%);
			border-bottom-color: $dropdown-color;
			margin: -1px -1px 0 -1px;
			z-index: 1000;
			position: relative;
			padding-bottom: 1px;
		}

		ul {

			margin: 0;
			padding: 0;
			list-style: none;

			&.navigation {
				display: inline-block;
				float: right;
				@extend .default-animation;

				margin-top: 1.4rem;

				li {
					float: left;
					position: relative;

					a {
						font-family: $font-family-header;
						display: inline-block;
						padding: 0.3rem 0.8rem;

						-webkit-backface-visibility: hidden;


						&:before, &:after {
							content: "";
							position: absolute;
							width: 100%;
							height: 1px;
							bottom: 0;
							left: 0;
							background-color: $core-accent;
							visibility: hidden;
							@include transform(scaleX(0));
							@include transition(all 0.2s ease);

						}

						&:hover:before {
							visibility: visible;
							@include transform(scaleX(0.75));
						}

						&.active:after {
							top: 0;
							visibility: visible;
							@include transform(scaleX(0.75));
						}
					}

					&.active {
						a:after {
							top: 0;
							visibility: visible;
							@include transform(scaleX(0.75));
						}
					}

					/*Active dropdown nav item */
					ul {
						position: absolute;
						background-color: $dropdown-color;
						border: 1px solid darken($dropdown-color, 4%);
						border-top: 0;
						min-width: 12rem;
						text-align: left;
						z-index: 999;
						left: -1px;
						display: none;
					}

					ul li {
						display: block;
						float: none;

						/* Active Dropdown nav item */
						&.active > a {
							background-color: darken($dropdown-color, 4%);
							color: $core-accent;
						}

						&:hover > a {
							background-color: $core-accent;
							color: $white;
						}

						a {
							display: block;
							margin: 0 -1px;

							&:after, &:before {
								display: none;
							}
						}
					}

					/* Dropdown CSS */
					ul ul {
						left: 100%;
						top: 0px;
					}

					/* Active on Hover */
					&:hover > ul {
						display: block;
					}

				}
				@include breakpoint(desktop-only) {
					display: none;
				}
			}	
		}

		.panel-activation {
			@extend %vertical-align;
            padding: 1rem;
			display: none;
			font-size: 1.8rem;
			cursor: pointer;
			float: right;
			@include breakpoint(desktop-only) {
				display: inline-block;
			}
		}

	}

}

// Header Image
.header-image {
    &.fullwidth {
        #body {
            padding-left: 0;
            padding-right: 0;

            >.listing-row {
                padding-left: $padding-horiz;
                padding-right: $padding-horiz;
            }
        }
    }
    .listing-row:last-child {
    	margin-bottom: 2rem;
    }

    #body {
        .flush-top {
            margin-top: - $header-height - $padding-vert - 1.5rem;
            padding-top: $header-height + 4rem;
        }
    }

    #breadcrumbs {
    	margin-top: 1rem;
    }

    #header {
        background-color: rgba($header-text,0);
        box-shadow: none;

		.navigation .has-children:hover a {
			color: $core-accent;
		}

        #logo h3, #logo a {
            color: $header-text;
        }
        a, .menu-btn {
            color: $header-text;
        }
        a:before, a:after {
        	background-color: rgba($header-text,0.7) !important;
        }

    }
}
