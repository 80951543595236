// Your custom SCSS should be written here...

.grav-lightslider {
    .lSSlideOuter {
        .lSPager.lSpg {
            > li a {
                z-index: 1;
            }
        }
    }
}

#body > script:first-child + .grav-lightslider {
  margin-top: -3rem;
}