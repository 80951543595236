fieldset {
	border: 1px solid $core-border-color;
}

textarea, #{$all-text-inputs}, select[multiple=multiple] {
	background-color: white;
	border: 1px solid $core-border-color;
	box-shadow: $core-box-shadow;

	&:hover {
		border-color: $core-border-color-hover;
	}

	&:focus {
		border-color: $core-border-color-focus;
		box-shadow: $core-box-shadow-focus;
	}
}

label {
	@extend strong;
}

// Forms
.form-field {
	.required {
		color: #F3443F;
		font-size: $core-font-size + 2rem;
		line-height: $core-font-size + 2rem;
		vertical-align: top;
		height: 1.5rem;
		display: inline-block;
	}
}

form {
	.buttons {
		text-align: center;
	}
	input {
		font-weight: 400;
	}
}
