// Error specific styling
#error {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding-bottom: 6rem;

	h1 {
		font-size: $core-font-size + 4.0rem;
	}

	p {
		margin: 1rem 0;
	}
}
